<template>
    <section id="Home"
        class="d-flex flex-column justify-content-end align-items-center w-100 p-0 overflow-hidden position-relative">
        <div id="carouselExampleAutoplaying" class="carousel carousel-fade" data-bs-ride="carousel">

            <div class="carousel-inner">
                <div class="carousel-item active" data-bs-interval="10000">
                    <div class="carousel-container">
                        <div class="carousel-container justify-content-center align-items-center">
                            <h2 class="animate__animated animate__fadeInDown">Welcome to <span>Agro Innova</span></h2>
                            <p class="animate__animated animate__fadeInUp">Agro Innova Co Ltd is an Agri-Tech Company 
                                improving agriculture in Africa by developing farmer-centered technologies to address 
                                challenges faced by farmers such as lack of access to ready market, climate-smart farming, lack of
                                access to inputs, farm traceability softwares, farm mananegment softwares and farmer data collection tools.</p>
                            <a href="/about/our_company" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" data-bs-interval="10000">
                    <div class="carousel-container">
                        <div class="carousel-container justify-content-center align-items-center">
                            <h2 class="animate__animated animate__fadeInDown">Our Services</h2>
                            <p class="animate__animated animate__fadeInUp">Agro Innova deploys a suite of software tools and
                                technology-enabled platforms which help to streamline business across
                                Africa's agricultural industry.</p>
                            <a href="#About" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" data-bs-interval="10000">
                    <div class="carousel-container">
                        <div class="carousel-container justify-content-center align-items-center">
                            <h2 class="animate__animated animate__fadeInDown">We are making an impact</h2>
                            <p class="animate__animated animate__fadeInUp">
                                We maintain a culture of creativity and influence that are aligned to impactful stakeholder experience and
                                to economic
                                growth.
                            </p>
                            <a href="/about/our_impact" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

        <svg class="Home-waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28 " preserveAspectRatio="none">
            <defs>
                <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g class="wave1">
                <use xlink:href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
            </g>
            <g class="wave2">
                <use xlink:href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
            </g>
            <g class="wave3">
                <use xlink:href="#wave-path" x="50" y="9" fill="#fff" />
            </g>
        </svg>

    </section>
</template>

<script>
import axios from 'axios';
import { carouselEndpoint } from '@/endpoint/endpoint';

export default {
    name: "Carousel",

    data() {
        return {
            carousels: [],
            loading: true
        }
    },

    mounted() {
        this.getCarousels();
    },

    methods: {
        async getCarousels() {
            try {
                const response = await axios.get(carouselEndpoint)
                const results = response?.data?.data
                this.carousels.push(results)
                if (response) {
                    this.loading = false
                }


            } catch (error) {
                console.error(error)
                this.loading = true
            }
        }
    }
}
</script>

<style scoped>
#Home {
    background: url("../../assets/img/FBS-140\ -\ Copy.JPG");
    background-size: cover;
    background-position: center;
}

#Home .carousel-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    height: 85vh;
    padding-top: 60px;
}

#Home h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700;
}

#Home p {
    width: 80%;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    margin: 0 auto 30px auto;
    color: #fff;
}

.btn-get-started {

    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    text-decoration: none;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #fff;
}

.btn-get-started:hover {
    background: #fff;
    color: #2a2c39;
    text-decoration: none;
}


@media (min-width: 1024px) {
    #Home p {
        width: 60%;
    }

    #Home .carousel-control-prev,
    #Home .carousel-control-next {
        width: 5%;
        text-decoration: none;
    }
}

@media (max-width: 1023px) {
    #Home .carousel-container {
        height: 90vh;
        padding-top: 50px;
    }

    #Home .carousel-control-prev,
    #Home .carousel-control-next {
        width: 10%;
        text-decoration: none;
    }

    #Home h2 {
        font-size: 28px;
    }
}

.Home-waves {
    display: block;
    width: 100%;
    height: 60px;
    z-index: 10;
    bottom: 0;
    position: absolute;
}

.wave1 use {
    -webkit-animation: move-forever1 10s linear infinite;
    animation: move-forever1 10s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave2 use {
    -webkit-animation: move-forever2 8s linear infinite;
    animation: move-forever2 8s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave3 use {
    -webkit-animation: move-forever3 6s linear infinite;
    animation: move-forever3 6s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }

    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }

    100% {
        transform: translate(-90px, 0%);
    }
}

@-webkit-keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@-webkit-keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}
</style>