<template>
    <div>
      <Layout />
    </div>
</template>

<script>
import Layout from './layout/Layout.vue';

import AOS from "@/assets/vendor/aos/aos.js";

export default {
  name: 'App',
  components: {
    Layout
},
mounted(){
  
    console.log("I am working---------AOS")
      AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false
      })
}
}
</script>

<style>
#app {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2a2c39;
}

::selection {
  background: #3db49d;
  color: white;
}
</style>
