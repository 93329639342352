<template>
    <section id="Home" class="d-flex flex-column justify-content-end align-items-center w-100 p-0 overflow-hidden position-relative">
        <div id="carouselHero" class="carousel slide carousel-fade" data-bs-interval="7000" data-bs-ride="carousel">
            <div v-if="loading" class="carousel-container d-flex justify-content-center align-items-center p-5">
                <div className="spinner-border text-white m-5 p-3" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>

            

            <div class="carousel-inner" v-else>
                <div class="carousel-item active">
                    <div class="carousel-container">
                        <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1680004706/AGRICULTURAL_DIGITIZATION_7ec5323a52.jpg" 
                            class="d-block img-fluid" alt="...">
                        <div class="title animate__animated animate__fadeInLeft text-start px-5 py-3">
                            <span>AGRICULTURAL</span>
                            <br />
                            <span style="color:#54f78f; font-size: 60px;">DIGITALIZATION</span>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-container">
                        <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1680022876/agroinnova_626611b1e8.jpg"
                            class="d-block img-fluid" alt="...">
                        <div class="title animate__animated animate__fadeInLeft text-start px-5 py-3">
                            <span>SMALLHOLDER</span>
                            <br />
                            <span style="color:#f6e466; font-size: 60px;">FARMER</span>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-container">
                        <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1680023623/akokomarket_0f0db97d84.jpg"
                            class="d-block img-fluid" alt="...">
                        <div class="title animate__animated animate__fadeInLeft text-start px-5 py-3">
                            <span>ACCESS TO</span>
                            <br />
                            <span style="color:#ebba93; font-size: 60px;">MARKET</span>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-container">
                        <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1680023986/akokotakra_0c670808e9.jpg"
                            class="d-block img-fluid" alt="...">
                        <div class="title animate__animated animate__fadeInLeft text-start px-5 py-3">
                            <span>CLIMATE-SMART</span>
                            <br />
                            <span style="color:#f4e79c; font-size: 60px;">FARMING</span>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-container">
                        <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1680024344/fbsinnova_4d0a55e450.jpg"
                            class="d-block img-fluid" alt="...">
                        <div class="title animate__animated animate__fadeInLeft text-start px-5 py-3">
                            <span>GLOBAL FOOD</span>
                            <br />
                            <span style="color:#f3fc6f; font-size: 60px;">SECURITY</span>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-container">
                        <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1680024935/YOUTH_and_WOMEN_EMP_copy_e614da47f8.jpg"
                            class="d-block img-fluid" alt="...">
                        <div class="title animate__animated animate__fadeInLeft text-start px-5 py-3">
                            <span>YOUTH AND WOMEN</span>
                            <br />
                            <span style="color:#ffb2c0; font-size: 60px;">EMPOWERMENT</span>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselHero"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselHero"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

        <svg class="Home-waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28 " preserveAspectRatio="none">
            <defs>
                <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g class="wave1">
                <use xlink:href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
            </g>
            <g class="wave2">
                <use xlink:href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
            </g>
            <g class="wave3">
                <use xlink:href="#wave-path" x="50" y="9" fill="#fff" />
            </g>
        </svg>

    </section>
</template>

<script>
import axios from 'axios';
import { carouselEndpoint } from '@/endpoint/endpoint';

export default {
    name: "Carousel-large",

    data () {
        return {
            carousels: [],
            loading: true
        }
    },

    mounted () {
        this.getCarousels();
    },

    methods: {
        async getCarousels(){
            try{
                const response = await axios.get(carouselEndpoint)
                const results = response?.data?.data
                this.carousels.push(results)
                if (response) {
                    this.loading = false
                }


            } catch(error) {
                    console.error(error)
                    this.loading = true
                }
        }
    }
}
</script>

<style scoped>
#Home {
    background: url("../../assets/img/FBS-140\ -\ Copy.JPG");
    background-size: cover;
    background-position: center;
}

#Home .carousel-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    height: 80vh;
    padding-top: 60px;
}

#Home img {
    z-index: 0;
}

#Home .title {
    z-index: 1;
    left: 0;
    top: 40%;
    line-height: 1;
    border: 5px solid transparent;
    border-image: linear-gradient(50deg, transparent, transparent, white);
    border-image-slice: 1;
    border-radius: 50px;
    position: absolute;
    margin-left: 130px;
}

#Home span {
    color: #ffffff;
    font-size: 50px;
    font-weight: 900;
}

.btn-get-started {

    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    text-decoration: none;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #fff;
}

.btn-get-started:hover {
    background: #fff;
    color: #2a2c39;
    text-decoration: none;
}


@media (min-height: 1366px) {
    #Home .carousel-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        position: relative;
        max-height: 32vh;
        padding-top: 60px;
    }
}

@media (max-width: 1024px) {
    #Home .carousel-container {
        height: 75vh;
    }

    #Home .carousel-control-prev,
    #Home .carousel-control-next {
        width: 10%;
        text-decoration: none;
    }

    #Home span {
        font-size: 28px;
    }
}

@media (max-width: 1300px) {
    #Home .carousel-container {
        height: 65vh;
    }

    #Home .carousel-control-prev,
    #Home .carousel-control-next {
        width: 10%;
        text-decoration: none;
    }

    #Home span {
        font-size: 28px;
    }
}

.Home-waves {
    display: block;
    width: 100%;
    height: 60px;
    z-index: 10;
    bottom: 0;
    position: absolute;
}

.wave1 use {
    -webkit-animation: move-forever1 10s linear infinite;
    animation: move-forever1 10s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave2 use {
    -webkit-animation: move-forever2 8s linear infinite;
    animation: move-forever2 8s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave3 use {
    -webkit-animation: move-forever3 6s linear infinite;
    animation: move-forever3 6s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }

    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }

    100% {
        transform: translate(-90px, 0%);
    }
}

@-webkit-keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@-webkit-keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}
</style>