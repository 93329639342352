<template>
    <section id="impact" class="impact">
        <div class="member-container row py-4" v-for="sdg in sdgs" :key="sdg.id">
            <div class="p-4 fw-bold fs-3" data-aos="fade-up">
                <p>WE ARE MAKING AN IMPACT</p>
            </div>
            <div class="member-card col-lg-3 col-md-6 d-flex align-items-stretch" v-for="el in sdg" :key="el.attributes.title">
                <div class="member" data-aos="fade-up" :data-aos-delay="el.attributes.delay">
                    <div class="member-img">
                        <img :src="el.attributes.imageTitle" class="img-fluid" :alt="el.attributes.title">
                        <div class="anchor">
                            <div>
                                <a href="/about/our_impact" class="fw-bold">{{el.attributes.text}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="member-container row py-4 bg-white" v-for="award in awards" :key="award.id">
            <div class="p-4 fw-bold fs-3" data-aos="fade-up" style="color: #2a2c39">
                <p>OUR AWARDS</p>
            </div>
            <div class="member-card col-lg-3 col-md-6 d-flex align-items-stretch" v-for="el in award" :key="el.attributes.imageTitle">
                <div class="member" data-aos="fade-up" :data-aos-delay="el.attributes.delay">
                    <div class="member-img d-flex align-items-center">
                        <img :src="el.attributes.imageTitle" class="img-fluid" :alt="el.attributes.imageTitle">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { sdgEndpoint, awardEndpoint } from '@/endpoint/endpoint';

export default {
    name: "Impact",
    data() {
        return {
            sdgs: [],
            awards: [],
            loading: true
        }
    },
    mounted () {
        this.getSdgs()
        this.getAwards()
    },
    methods: {
        async getSdgs(){
            try {
                const response = await axios.get(sdgEndpoint)
                const results = response?.data?.data
                this.sdgs.push(results)
                if (response) {
                    this.loading = false
                }
            } catch(error) {
                console.error(error)
                this.loading = true
            }
        },

        async getAwards() {
            try {
                const response = await axios.get(awardEndpoint)
                const results = response?.data?.data
                this.awards.push(results)
                if (response) {
                    this.loading = false
                }
            } catch (error) {
                console.error(error)
                this.loading = true
            }
        } 
    }
}
</script>

<style scoped>
.impact .member-container {
    background-color: #2a2c39;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.impact .member-card {
    width: 18rem;
}

.impact .member {
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 5px;
    height: 250px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.impact .member .member-img {
    position: relative;
    overflow: hidden;
    height: inherit;
}

.impact .member .anchor {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: auto;
    height: 100px;
    background: #fff;
    border-radius: 5px;
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.impact .member .anchor a {
    transition: color 0.3s;
    transition: ease-in-out 0.3s;
    color: #2a2c39;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.impact .member .anchor a:hover {
    color: #3db49d;
}

.impact .member .anchor i {
    font-size: 18px;
    line-height: 0;
}

.impact .member:hover .anchor {
    opacity: 1;
    bottom: 10px;
}
</style>