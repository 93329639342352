<template>
    <section id="impact" class="impact">
        <div class="container text-start">

            <div class="section-title" data-aos="zoom-out">
                <h2>Our Impact</h2>
                <p>A Global Agenda</p>
            </div>

            <div>
                <div class="row" data-aos="fade-up">
                    <p class="pb-4" data-aos="fade-up">
                        We maintain a culture of creativity and influence that are aligned to impactful stakeholder
                        experience and to economic growth. In combination with advanced thinking, continuous research 
                        and collaboration with our partners, we develop effective tools that provide digitalized solutions 
                        to agricultural challenges and global development goals. These tools ensure;
                    </p>

                    <div v-if="loading" class="d-flex justify-content-center align-items-center p-5">
                        <div className="spinner-border text-dark m-5 p-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div v-else v-for="banner in banners" :key="banner.id">
                        <div class="mb-5" data-aos="fade-up" v-for="el in banner" :key="el.attributes.title">
                            <div class="">
                                <div>
                                    <img :src="el.attributes.imageTitle" class="img-fluid" :alt="el.attributes.title" />
                                </div>
                                <div class="pt-4 pb-5">
                                    <h2 class="" :style="{'color' : el.attributes.textColor}">{{el.attributes.subtitle}}</h2>
                                    <p>
                                        {{el.attributes.text}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row d-flex justify-content-center align-items-center flex-wrap" v-for="award in awards" :key="award.id">
                    <div class="pb-3" data-aos="zoom-out">
                        <h4 class="fw-bold">Awards and Recognition</h4>
                    </div>
                
                    <div class="award-card col-lg-3 col-md-6 d-flex align-items-stretch" v-for="el in award" :key="el.attributes.imageTitle">
                        <div link class="award d-flex" data-aos="fade-up" :data-aos-delay="el.attributes.delay">
                            <div class="award-img d-flex align-items-center">
                                <img :src="el.attributes.imageTitle" class="img-fluid" :alt="el.attributes.imageTitle">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { bannerEndpoint, awardEndpoint } from '@/endpoint/endpoint';

export default {
    name: "Impact",

    data () {
        return {
            banners: [],
            awards: [],
            loading: true
        }
    },
    mounted() {
        document.title = "Our Impact - A global agenda";
        this.getBanners()
        this.getAwards()
    },
    methods: {
        async getBanners () {
            try {
                const response = await axios.get(bannerEndpoint)
                const results = response?.data?.data
                this.banners.push(results)
                if (response) {
                    this.loading = false
                }
            } catch(error) {
                this.loading = true
                console.error(error)
            }
        },
         async getAwards() {
            try {
                const response = await axios.get(awardEndpoint)
                const results = response?.data?.data
                this.awards.push(results)
                if (response) {
                    this.loading = false
                }
            } catch (error) {
                console.error(error)
                this.loading = true
            }
        } 
    }
}
</script>

<style scoped>






section {
    padding: 120px 0 50px 0;
    overflow: hidden;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #3db49d;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2a2c39;
}

.impact {
    background: #fff;
}

.impact .award-card {
    width: 17rem;
}

.impact .award {
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 5px;
    background: #fff;
    height: 250px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.impact .award .award-img {
    height: inherit;
    position: relative;
    overflow: hidden;
}

.impact .award a {
    text-decoration: none;
}

/* .impact .award .award-info {
    padding: 25px 15px;
}

.impact .award .award-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #2a2c39;
}

.impact .award .award-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
}

.impact .award .award-info p {
    font-style: italic;
    font-size: 14px;
    line-height: 26px;
    color: #777777;
} */
</style>