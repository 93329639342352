<template>
    <div class="d-block d-lg-none">
        <Carousel />
    </div>
    <div class="d-none d-lg-block">
        <CarouselLarge />
    </div>
    <main id="main" />

    <!-- ======= About Section ======= -->
    <section id="About" class="About">
        <div class="container section-title text-start" data-aos="zoom-out">
            <h2>About</h2>
            <p>What we do</p>
        </div>
        <About />
    </section>
    <!-- End About Section -->

    <!-- ======= Products Section ======= -->
    <section id="Products" class="Products">
        <div class="container section-title text-start" data-aos="zoom-out">
            <h2>Products</h2>
            <p>What we offer</p>
        </div>
        <Products />
    </section>
    <!-- End Products Section -->

    <!-- ======= Impact Section ======= -->
    <section id="impact" class="impact">
        <Impact />
    </section>
    <!-- End Impact Section -->

    <!-- ======= Aricles Section ======= -->
    <section id="Articles" class="Articles">
        <div class="container section-title text-start" data-aos="zoom-out">
            <h2>Articles</h2>
            <p>What's happening</p>
        </div>
        <Articles />
    </section>
    <!-- End Articles Section -->

    <!-- ======= Contact Section ======= -->
    <section id="Contact" class="Contact">
        <div class="container section-title text-start" data-aos="zoom-out">
            <h2>Contact</h2>
            <p>Let's Get Talking</p>
        </div>
        <Contact />
    </section>
    <!-- End Contact Section -->

</template>

<script>
import Carousel from '@/components/carousel/Carousel.vue';
import CarouselLarge from '@/components/carousel/Carousel-large.vue';
import Products from '@/components/products/Products.vue';
import About from '../../components/about/About.vue';
import Impact from '@/components/impact/impact.vue';
import Articles from '@/components/articles/Articles.vue';
import Contact from '@/components/contact/contact.vue';

export default {
    name: 'Home',
    components: {
    Carousel,
    CarouselLarge,
    Products,
    About,
    Impact,
    Articles,
    Contact
}
}
</script>

<style scoped>

/*--------------------------------------------------------------
# Section
--------------------------------------------------------------*/
section {
    padding: 50px 0 20px 0;
    overflow: hidden;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #3db49d;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    line-height: normal;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2a2c39;
}
</style>