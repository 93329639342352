// const endpoint = process.env.VUE_APP_STRAPI_ENDPOINT;
const endpoint = 'https://agroinnova-website-strapi.herokuapp.com/'

export const carouselEndpoint = endpoint + "api/carousels";
export const sdgEndpoint = endpoint + "api/sdgs";
export const bannerEndpoint = endpoint + "api/sdg-banners";
export const awardEndpoint = endpoint + "api/awards";
export const productEndpoint = endpoint + "api/products?sort[0]=id";
export const galleryEndpoint = endpoint + "api/galleries";
export const partnerEndpoint = endpoint + "api/partners?sort[0]=id";
export const teamEndpoint = endpoint + "api/teams?sort[0]=id";
export const advisorEndpoint = endpoint + "api/advisors";
export const articleEndpoint = endpoint + "api/articles";