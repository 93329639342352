<template>
    <div class="contact">
        <div class="container">

            <div class="row mt-5 mb-3">

                <div class="col-lg-4" data-aos="fade-right">
                    <div class="info text-start">
                        <div class="address">
                            <i class="bi bi-geo-alt"></i>
                            <h4>Location:</h4>
                            <p>
                                <a class="call" href="https://www.google.com/maps/place/AkokoMarket+Ghana/@5.6695421,-0.1557767,15z/data=!3m1!4b1!4m5!3m4!1s0xfdf9dea827dc623:0xbd093fcdfba398a4!8m2!3d5.6694641!4d-0.1470546" 
                                target="blank"
                                rel="noopener noreferrer">No. 31 Ogbojo Koo St, Accra</a>
                            </p>
                        </div>

                        <div class="email">
                            <i class="bi bi-envelope"></i>
                            <h4>Email:</h4>
                            <p>info@agroinnovagh.com</p>
                        </div>

                        <div class="phone">
                            <i class="bi bi-phone"></i>
                            <h4>Call:</h4>
                            <p><a href="tel:+233302557422" class="call">+233 30 255 7422</a> / <a
                                    href="tel:+233240956276" class="call">+233 240 956 276</a></p>
                        </div>
                    </div>

                </div>

                <div class="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">

                    <form @submit.prevent="sendMail" class="php-email-form">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <input
                                    type="text"
                                    name="name"
                                    class="form-control"
                                    id="name"
                                    placeholder="Your Name"
                                    v-model="contactFormData.name"
                                    required
                                />
                            </div>
                            <div class="col-md-6 form-group mt-3 mt-md-0">
                                <input
                                    type="email"
                                    class="form-control"
                                    name="email"
                                    id="email"
                                    v-model="contactFormData.email"
                                    placeholder="Your Email"
                                    required
                                />
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <input
                                type="text"
                                class="form-control"
                                name="subject"
                                id="subject"
                                v-model="contactFormData.subject"
                                placeholder="Subject"
                                required
                            />
                        </div>
                        <div class="form-group mt-3">
                            <textarea
                                class="form-control"
                                name="message"
                                v-model="contactFormData.message"
                                rows="5"
                                placeholder="Message"
                                required
                            >
                            </textarea>
                        </div>
                        <div class="my-3">
                            <div class="loading">Loading</div>
                            <div class="error-message"></div>
                            <div class="sent-message">Your message has been sent. Thank you!</div>
                        </div>
                        <div class="text-center">
                          <button type="submit">Send Message</button>
                        </div>
                    </form>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {endpointConfig} from "../../../functions/config";

export default {
  name: "Contact",

  data(){
    return{
      contactFormData: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
      success: false,
      error: false
    }
  },

  methods: {
    sendMail(){
      const url = endpointConfig.host

      const {name, email, subject, message} = this.contactFormData
      const payload = {name, email, subject, message}

      console.log('Start sending ********')

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(() => {
        this.success = true;
        this.resetForm()
      }).catch(() => {
        this.error = true
      })
    },

    resetForm(){
      this.contactFormData = {
        name: '',
        email: '',
        subject: '',
        message: ''
      }
    }
  }
}
</script>

<style scoped>




.contact .info {
    width: 100%;
    background: #fff;
}

.contact .info i {
    font-size: 20px;
    color: #3db49d;
    float: left;
    width: 44px;
    height: 44px;
    background: #8beeda;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #2a2c39;
}

.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
}

.contact .info .email,
.contact .info .phone {
    margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
    background: #3db49d;
    color: #fff;
}

.call {
    font-family: "Poppins";
    text-decoration: none;
}

.contact .php-email-form {
    width: 100%;
    background: #fff;
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #e90707;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br+br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #46c039;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
    background: transparent;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    border: 2px solid #3db49d;
    padding: 10px 24px;
    color: #3db49d;
    transition: 0.4s;
    border-radius: 5px;
}

.contact .php-email-form button[type=submit]:hover {
    background: #3db49d;
    color: #fff;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>