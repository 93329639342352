<template>
    <section id="Gallery">
        <div class="container text-start">
            <div class="section-title" data-aos="zoom-out">
                <h2>Gallery</h2>
                <p>Our Cherished Memories</p>
            </div>

            <div class="gallery">
                <div class="container">
                    <ul id="gallery-flters" class="d-flex justify-content-end" data-aos="fade-up">
                        <li data-filter="*" class="filter-active">All</li>
                        <li data-filter=".filter-fbs">FBS Sessions</li>
                        <li data-filter=".filter-pjt">Workshops</li>
                        <li data-filter=".filter-cbn">Celebrations</li>
                    </ul>

                    <div class="row gallery-container" data-aos="fade-up">
                    
                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393542/FBS_123_065a6d7eb7.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393542/FBS_123_065a6d7eb7.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>
                    
                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393571/FBS_161_950047d671.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393571/FBS_161_950047d671.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>
                    
                        <div class="col-lg-4 col-md-6 gallery-item filter-pjt" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393573/IMG_20190813_WA_0054_20120eb9ee.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393573/IMG_20190813_WA_0054_20120eb9ee.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>
                    
                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393566/FBS_167_7c79985706.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393566/FBS_167_7c79985706.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>
                    
                        <div class="col-lg-4 col-md-6 gallery-item filter-pjt" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393572/IMG_20190813_WA_0018_a5a0f027a6.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393572/IMG_20190813_WA_0018_a5a0f027a6.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>
                    
                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393567/FBS_178_7d9daa4f72.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393567/FBS_178_7d9daa4f72.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>
                    
                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393547/FBS_160_5654aa64bc.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393547/FBS_160_5654aa64bc.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>
                    
                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393552/FBS_164_9b55108c99.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393552/FBS_164_9b55108c99.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393542/FBS_123_065a6d7eb7.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393542/FBS_123_065a6d7eb7.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393512/FBS_140_68d93f267c.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393512/FBS_140_68d93f267c.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393500/FBS_107_7c9301539b.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393500/FBS_107_7c9301539b.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393483/FBS_144_6d719a342a.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393483/FBS_144_6d719a342a.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393471/FBS_98_20e191c099.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393471/FBS_98_20e191c099.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393459/FBS_76_a3088d8d31.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393459/FBS_76_a3088d8d31.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393456/FBS_120_9d1c26e97e.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393456/FBS_120_9d1c26e97e.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393443/FBS_77_ef25bc1d4a.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393443/FBS_77_ef25bc1d4a.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393436/FBS_14_dbb2323f42.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393436/FBS_14_dbb2323f42.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393427/FBS_91_5710c3e360.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393427/FBS_91_5710c3e360.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-fbs" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393405/FBS_28_c69f62ec3f.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393405/FBS_28_c69f62ec3f.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-cbn" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393379/7333_35646237fa.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393379/7333_35646237fa.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-pjt" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393341/e819_fa9e93d88b.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393341/e819_fa9e93d88b.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-cbn" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393339/a1_2c3504e1a2.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393339/a1_2c3504e1a2.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-cbn" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393339/5_min_da9f72d975.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393339/5_min_da9f72d975.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-cbn" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393339/a3_c4cf81ee03.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393339/a3_c4cf81ee03.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-pjt" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393338/a67a_6e6a061242.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393338/a67a_6e6a061242.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 gallery-item filter-cbn" data-aos="fade-up">
                            <div class="gallery-img"><img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393338/6_min_3aac7573b1.jpg" class="img-fluid" alt=""></div>
                            <div class="gallery-info">
                                <a href="https://res.cloudinary.com/dp9w9miha/image/upload/v1665393338/6_min_3aac7573b1.jpg" data-gallery="portfolioGallery"
                                    class="gallery-lightbox preview-link"><i class="bi bi-fullscreen"></i></a>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { select } from '@/utility/comon/Common';
import GLightbox from "../../assets/vendor/glightbox/js/glightbox.min.js";
import AOS from "../../assets/vendor/aos/aos.js";
import Isotope from '@/assets/vendor/isotope-layout/isotope.pkgd.min.js';

export default {
    name: "Gallery",

    data() {
        return {
            galleries: [],
            loading: true,

        }
    },

    mounted() {
        document.title = "Galleries - Our cherished memories"
        this.galleryIsotopeHandler();
        this.galleryLightboxHandler();
    },

    methods: {

        on(type, el, listener, all = false) {
            let selectEl = select(el, all)
            if (selectEl) {
                if (all) {
                    selectEl.forEach(e => e.addEventListener(type, listener))
                } else {
                    selectEl.addEventListener(type, listener)
                }
            }
        },

        galleryIsotopeHandler() {
            window.addEventListener('load', () => {
                let galleryContainer = select('.gallery-container');
                if (galleryContainer) {
                    let galleryIsotope = new Isotope(galleryContainer, {
                        itemSelector: '.gallery-item',
                    });

                    let galleryFilters = select('#gallery-flters li', true);

                    this.on('click', '#gallery-flters li', function (e) {
                        e.preventDefault();
                        galleryFilters.forEach(function (el) {
                            el.classList.remove('filter-active');
                        });
                        this.classList.add('filter-active');

                        galleryIsotope.arrange({
                            filter: this.getAttribute('data-filter')
                        });
                        galleryIsotope.on('arrangeComplete', function () {
                            AOS.refresh()
                        });
                    }, true);
                }
            });
        },

        galleryLightboxHandler() {
            const galleryLightbox = GLightbox({
                selector: '.gallery-lightbox'
            });
        },
    }    
}
</script>

<style scoped>
section {
    padding: 120px 0 50px 0;
    overflow: hidden;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #3db49d;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2a2c39;
}

.gallery #gallery-flters {
    list-style: none;
    margin-bottom: 20px;
}

.gallery #gallery-flters li {
    cursor: pointer;
    display: inline-block;
    margin: 0 0 10px 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    color: #aaa;
    background: #fff;
    border: 2px solid #aaa;
    border-radius: 5px;
    padding: 15px;
    transition: all 0.3s ease-in-out;
}
.gallery #gallery-flters li:hover {
    color: #3db49d;
    border: 2px solid #3db49d
}
.gallery #gallery-flters li.filter-active {
    color: #fff;
    border: 2px solid #3db49d;
    background: #3db49d;
}

.gallery .gallery-item {
    margin-bottom: 30px;
}

.gallery .gallery-item .gallery-img {
    border-radius: 10px;
    overflow: hidden;
}

.gallery .gallery-item .gallery-img img {
    transition: all 0.8s ease-in-out;
}

.gallery .gallery-item .gallery-info {
    opacity: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    width: 4rem;
    border-radius: 50px;
    left: auto;
    bottom: 10px;
    z-index: 3;
    right: 20px;
    transition: all ease-in-out 0.3s;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 15px;
}

.gallery .gallery-item .gallery-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
}

.gallery .gallery-item .gallery-info .preview-link,
.gallery .gallery-item .gallery-info .details-link {
    font-size: 24px;
    top: calc(50% - 18px);
    color: #fff;
    transition: 0.3s;
}

.gallery .gallery-item .gallery-info .preview-link:hover,
.gallery .gallery-item .gallery-info .details-link:hover {
    color: #8beeda;
}

.gallery .gallery-item .gallery-links {
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
}


.gallery .gallery-item:hover .gallery-img img {
    transform: scale(1.2);
}

.gallery .gallery-item:hover .gallery-info {
    opacity: 1;
}

@media (max-width: 767px) {
    .gallery #gallery-flters li {
        font-size: 12px;
    }
}

@media (max-width: 533px) {
    .gallery #gallery-flters li {
        font-size: 10px;
    }
}

@media (max-width: 482px) {
    .gallery #gallery-flters li {
        font-size: 9px;
        padding: 12px;
    }
}

@media (max-width: 436px) {
    .gallery #gallery-flters li {
        font-size: 8px;
        padding: 10px;
    }
}

@media (max-width: 396px) {
    .gallery #gallery-flters li {
        font-size: 7px;
        padding: 8px;
    }
}
</style>