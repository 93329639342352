<template>
    <div class="container about">
        <div class="row content text-start pb-3" data-aos="fade-up">
            <div class="col-lg-6">
                <p>
                    We empower smallholder farmers, women and youth in agriculture by developing farmer-centered digital innovation to
                    address challenges faced by these farmers and the entire value chain in Africa.
                </p>
                <ul>
                    <li><i class="ri-check-double-line"></i> Access to Market</li>
                    <li><i class="ri-check-double-line"></i> Input Financing</li>
                    <li><i class="ri-check-double-line"></i> Traceability Software</li>
                    <li><i class="ri-check-double-line"></i> Climate Smart Farming</li>
                    <li><i class="ri-check-double-line"></i> Farm Management Software</li>
                </ul>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
                <p>
                    Through our digital platforms, smallholder farmers get formalized in their day-to-day farm activities thereby empowering
                    them to make data driven decisions which are key to their business growth.
                </p>
                <a href="/#Products" class="btn-learn-more">Learn More</a>
            </div>
        </div>
    </div>
    <div class="row cards-bg text-center mt-4">
        <section id="cards" class="cards">
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <div class="p-4 text-center fw-bold fs-3" data-aos="fade-up">
                        <p>WHAT WE HAVE DONE</p>
                    </div>
                    <div class=" col-lg-3 col-md-6 d-flex justify-content-center align-items-stretch">
                        <div class="member mt-2 mb-3" data-aos="fade-up">
                            <div class="member-info">
                                <div class="d-flex justify-content-center">
                                    <h4 data-purecounter-start="0" data-purecounter-end="20" data-purecounter-duration="1"
                                            class="counter purecounter"></h4>
                                    <h4>K+</h4>
                                </div>
                                <span>Monthly Sales Transactions</span>
                                <div class="anchor">
                                    <div class="text-start">
                                        <ul>
                                            <li>Poultry Eggs</li>
                                            <li>Maize</li>
                                            <li>Soya Beans</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 d-flex justify-content-center align-items-stretch">
                        <div class="member mt-2 mb-3" data-aos="fade-up" data-aos-delay="100">
                            <div class="member-info">
                                <div class="d-flex justify-content-center">
                                    <h4 data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1"
                                            class="counter purecounter"></h4>
                                    <h4>K+</h4>
                                </div>
                                <span>Farmers Reached</span>
                                <div class="anchor">
                                    <div class="text-start">
                                        <ul>
                                            <li>Crop Farmers</li>
                                            <li>Livestock Farmers</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 d-flex justify-content-center align-items-stretch">
                        <div class="member mt-2 mb-3" data-aos="fade-up" data-aos-delay="200">
                            <div class="member-info">
                                <div class="d-flex justify-content-center">
                                    <h4 data-purecounter-start="0" data-purecounter-end="500" data-purecounter-duration="1" class="counter purecounter">
                                    </h4>
                                    <h4>+</h4>
                                </div>
                                <span>Direct & Indirect Jobs created</span>
                                <div class="anchor">
                                    <div class="text-start">
                                        <ul>
                                            <li>Women</li>
                                            <li>Youth</li>
                                            <li>Informal Sector</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 d-flex justify-content-center align-items-stretch">
                        <div class="member mt-2 mb-3" data-aos="fade-up" data-aos-delay="300">
                            <div class="member-info">
                                <div class="d-flex justify-content-center">
                                    <h4 data-purecounter-start="0" data-purecounter-end="9" data-purecounter-duration="1" class="counter purecounter">
                                    </h4>
                                    <h4>+</h4>
                                </div>
                                <span>Countries of Adoption</span>
                                <div class="anchor">
                                    <div class="d-flex justify-content-center text-start fw-bold">
                                        <div class="p-2">
                                            <div>Ghana</div>
                                            <div>Nigeria</div>
                                            <div>Tunisia</div>
                                            <div>Namibia</div>
                                            <div>Mali</div>
                                        </div>
                                        <div class="p-2">
                                            <div>Burkina Faso</div>
                                            <div>Mozambique</div>
                                            <div>Cote d'Ivore</div>
                                            <div>Cameroon</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    </div>
</template>

<script>
import PureCounter from '@/assets/vendor/purecounter/purecounter_vanilla';

export default {
    name: "About",
    mounted() {
        this.counterHandler()
    },
    methods: {
        counterHandler(){
            console.log("I am working---------PureCounter")
            new PureCounter();
        }
    }
}
</script>

<style scoped>
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content {
    position: relative;
    z-index: 0;
}

.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-left: 28px;
    position: relative;
}

.about .content ul li+li {
    margin-top: 10px;
}

.about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #3db49d;
    line-height: 1;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .btn-learn-more {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    text-decoration: none;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #3db49d;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #3db49d;
}

.about .content .btn-learn-more:hover {
    background: #3db49d;
    color: #fff;
}

/*--------------------------------------------------------------
# Cards
--------------------------------------------------------------*/

.cards {
    color: #2a2c39;
}

.cards .member {
    background-color: #2a2c39;
    border-radius: 5px;
    width: 25rem;
    height: 150px;
}

.cards .member .member-info {
    padding: 25px 15px;
    color: #fff;
}

.cards .member .member-info h4 {
    font-weight: 500;
    margin-bottom: 5px;
    font-family: "Poppins";
    font-size: 40px;
}

.cards .member .member-info .anchor {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: auto;
    height: 100%;
    background: #3db49d;
    border-radius: 5px;
    transition: cubic-bezier(0.04, 0.28, 0.58, 1) 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cards .member:hover .anchor {
    opacity: 1;
}

.cards .member .member-info .anchor li{
    font-weight: bolder;
}

.cards .member .member-info span {
    display: block;
    font-size: 16px;
    font-weight: 400;
}
</style>