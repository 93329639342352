import Home from "../view/home/Home.vue"
import Gallery from "../view/gallery/Gallery.vue"
import Company from "../view/about/company/Company.vue"
import Impact from "../view/about/impact/Impact.vue"
import Partners from "../view/about/partners/Partners.vue"

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/gallery",
        name: "Gallery",
        component: Gallery
    },
    {
        path: "/about/our_company",
        name: "Our Company",
        component: Company
    },
    {
        path: "/about/our_impact",
        name: "Our Impact",
        component: Impact
    },
    {
        path: "/about/our_partners",
        name: "Our Partners",
        component: Partners
    }
]

export default routes;