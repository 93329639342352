import "../bootstrap.css"
import "@/assets/vendor/animate.css/animate.min.css" 
import "@/assets/vendor/aos/aos.css" 
import "@/assets/vendor/bootstrap-icons/bootstrap-icons.css" 
import "@/assets/vendor/boxicons/css/boxicons.min.css" 
import "@/assets/vendor/glightbox/css/glightbox.min.css" 
import "@/assets/vendor/remixicon/remixicon.css" 
import "@/assets/vendor/swiper/swiper-bundle.min.css"


import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router"
import routes from "./routes/Routes"
import App from './App.vue'


const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

createApp(App).use(router).mount('#app')

import "@popperjs/core/dist/umd/popper.js"
import "bootstrap/dist/js/bootstrap.js"

import "@/assets/vendor/aos/aos.js"
import "@/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"
import "@/assets/vendor/glightbox/js/glightbox.min.js"
import "@/assets/vendor/isotope-layout/isotope.pkgd.min.js"
import "@/assets/vendor/swiper/swiper-bundle.min.js"
import "@/assets/vendor/php-email-form/validate.js"
import "@/assets/vendor/purecounter/purecounter_vanilla.js"

