<template>
    <div class="articles">
        <div class="container">
            <div class="articles-slider swiper" data-aos="fade-up" data-aos-delay="100">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="article-item">
                            <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1710339790/agroinnova_launches_424a21ad85.webp?updated_at=2024-03-13T14:23:13.596Z"
                                class="article-img" alt="">
                            <div class="info px-2">
                                <div class="txt">
                                    <a href="https://agroinnovagh.medium.com/agro-innova-launches-a-fulfilment-center-in-ghana-7f1f44761ab8" target="blank">
                                        <strong>Agro Innova launches a fulfilment center in Ghana</strong>
                                    </a>
                                    <!-- <p>
                                                    
                                                </p> -->
                                </div>
                            </div>
                            <div class="d-flex justify-content-center align-items-center pb-3" style="color:#999; font-family: poppins;">
                                <i class="ri-calendar-event-line fs-5 me-1"></i>
                                <h4>13/03/2024</h4>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="article-item">
                            <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1686053165/AV_Ventures_Ghana_Agro_Innova_Press_Release_089237d038.jpg"
                                class="article-img" alt="">
                            <div class="info px-2">
                                <div class="txt">
                                    <a href="https://www.linkedin.com/posts/acdivoca_av-ventures-invests-in-agro-innova-limited-activity-7080214069860851712-oiST/?utm_source=share&utm_medium=member_ios" target="blank">
                                        <strong>AV Ventures invests in Agro Innova Limited to digitalize the agriculture sector in West Africa - ACDI/VOCA</strong>
                                    </a>
                                    <!-- <p>
                                                    
                                                </p> -->
                                </div>
                            </div>
                            <div class="d-flex justify-content-center align-items-center pb-3" style="color:#999; font-family: poppins;">
                                <i class="ri-calendar-event-line fs-5 me-1"></i>
                                <h4>30/06/2023</h4>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="article-item">
                            <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1679996841/1679500100523_e141f90b5c.jpg"
                                class="article-img" alt="">
                            <div class="info px-2">
                                <div class="txt">
                                    <a href="https://www.linkedin.com/posts/startinev_saistival-2023-by-giz-activity-7043149274787790848-qXV8/?utm_source=li_share&utm_content=feedcontent&utm_medium=g_mb_web&utm_campaign=copy" target="blank">
                                        <strong>Agro Innova take part in the SAIStival 2023 by GIZ</strong>
                                    </a>
                                    <!-- <p>
                                                    
                                                </p> -->
                                </div>
                            </div>
                            <div class="d-flex justify-content-center align-items-center pb-3" style="color:#999; font-family: poppins;">
                                <i class="ri-calendar-event-line fs-5 me-1"></i>
                                <h4>3/21/2023</h4>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="article-item">
                            <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1678972719/1678959667412_ffda6bc9b4.jpg"
                                class="article-img" alt="">
                            <div class="info px-2">
                                <div class="txt">
                                    <a href="https://www.linkedin.com/posts/innohubconnect_developpp-ghana-smes-activity-7042067252589789184-n0aY/?utm_source=share&utm_medium=member_ios" target="blank">
                                        <strong>Agro Innova selected for the 2nd Cohort of the #develoPPP Ventures program</strong>
                                    </a>
                                    <!-- <p>
                                                        
                                                    </p> -->
                                </div>
                            </div>
                            <div class="d-flex justify-content-center align-items-center pb-3" style="color:#999; font-family: poppins;">
                                <i class="ri-calendar-event-line fs-5 me-1"></i>
                                <h4>3/16/2023</h4>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="article-item">
                            <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1676035995/submit_header_aee8b77c74.jpg"
                                class="article-img" alt="">
                            <div class="info px-2">
                                <div class="txt">
                                    <a href="https://www.linkedin.com/posts/ghana-innovation-journal_digitalmarketing-content-marketing-activity-7029033040974630912-RUoC/?utm_source=share&utm_medium=member_ios" target="blank">
                                        <strong>Agro Innova selected for the MEST Africa Scale Accelerator Programme</strong>
                                    </a>
                                    <!-- <p>
                                                            
                                                        </p> -->
                                </div>
                            </div>
                            <div class="d-flex justify-content-center align-items-center pb-3" style="color:#999; font-family: poppins;">
                                <i class="ri-calendar-event-line fs-5 me-1"></i>
                                <h4>2/8/2023</h4>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="article-item">
                            <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665429385/IMG_3638_af323ca84e.jpg" class="article-img" alt="">
                                <div class="info px-2">
                                    <div class="txt">
                                        <a href="https://www.myjoyonline.com/" target="blank">
                                            <strong>Agro Innova wins Outstanding Agro-Tech Company of the Year Award</strong>
                                        </a>
                                    <!-- <p>
                                        
                                    </p> -->
                                    </div>
                                </div>
                            <div class="d-flex justify-content-center align-items-center pb-3" style="color:#999; font-family: poppins;">
                                <i class="ri-calendar-event-line fs-5 me-1"></i>
                                <h4>8/17/2020</h4>
                            </div>
                        </div>
                    </div>

                    <div class="swiper-slide">
                        <div class="article-item">
                            <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665429379/George_town_c0283955ea.png"
                                class="article-img" alt="">
                            <div class="info px-2">
                                <div class="txt">
                                    <a href="#" target="blank">
                                        <strong>Agro Innova, Georgetown University Internship International Consulting Project - Spearheaded by Kosmos Energy and
                                            Hilltop Global Group
                                        </strong>
                                    </a>
                                    <!-- <p>
                                        <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                        In March 2019, Agro Innova had a truly profound information exchange and experience with the student consulting team from
                                        Georgetown University as part of their Global Business Experience (GBE).
                                        <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                    </p> -->
                                </div>
                            </div>
                            <div class="d-flex justify-content-center align-items-center pb-3" style="color:#999; font-family: poppins;">
                                <i class="ri-calendar-event-line fs-5 me-1"></i>
                                <h4>6/17/2019</h4>
                            </div>
                        </div>
                    </div>

                    <div class="swiper-slide">
                        <div class="article-item">
                            <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665429379/poultry_farm_1e72367168.jpg"
                                class="article-img" alt="">
                            <div class="info px-2">
                                <div class="txt">
                                    <a href="https://international-agriculture.com/poultry-farms-farmers-in-ghana-urge-to-undergo-biometric-registration/"
                                        target="blank">
                                        <strong>Poultry farms, farmers in Ghana urge to undergo Biometric Registration</strong>
                                    </a>
                                    <!-- <p>
                                        <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                        
                                        <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                    </p> -->
                                </div>
                            </div>
                            <div class="d-flex justify-content-center align-items-center pb-3" style="color:#999; font-family: poppins;">
                                <i class="ri-calendar-event-line fs-5 me-1"></i>
                                <h4>9/7/2018</h4>
                            </div>
                        </div>
                    </div>

                    <div class="swiper-slide">
                        <div class="article-item">
                            <img src="https://res.cloudinary.com/dp9w9miha/image/upload/v1665429375/fullsize_0f12f7ee9a.jpg"
                                class="article-img" alt="">
                            <div class="info px-2">
                                <div class="txt">
                                    <a href="https://www.snrd-africa.net/where-are-we-at-with-the-fbs-mobile-application-development/" target="blank">
                                        <strong>Where We’re at with Human Centered Design for the FBS App</strong>
                                    </a>
                                    <!-- <p>
                                        <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    
                                        <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                    </p> -->
                                </div>
                            </div>
                            <div class="d-flex justify-content-center align-items-center pb-3" style="color:#999; font-family: poppins;">
                                <i class="ri-calendar-event-line fs-5 me-1"></i>
                                <h4>3/12/2019</h4>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="swiper-pagination"></div>

            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "../../assets/vendor/swiper/swiper-bundle.min";
import axios from 'axios';
import { articleEndpoint } from '@/endpoint/endpoint';

export default {
    name: "Articles",
    data() {
        return {
            articles: [],
            loading: true
        }
    },
    mounted() {
        this.articlesSwiperHandler();
        this.getArticles();
    },
    methods: {
        articlesSwiperHandler(){
            new Swiper('.articles-slider', {
                speed: 500,
                loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                slidesPerView: 'auto',
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },

                    500: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },

                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    }
                }
            });
        },
        async getArticles() {
            try {
                const response = await axios.get(articleEndpoint)
                const results = response?.data?.data
                this.articles.push(results)
                if (response) {
                    this.loading = false
                }


            } catch (error) {
                console.error(error)
                this.loading = true
            }
        }
    }
}
</script>

<style>
.articles .articles-carousel,
.articles .articles-slider {
    overflow: hidden;
}

.articles .article-item {
    box-sizing: content-box;
    border-radius: 10px;
    margin: 15px 15px;
    text-align: center;
    height: 370px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
}

.articles .article-item .article-img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
}

.articles .article-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
}

.articles .article-item h4 {
    font-size: 10px;
    color: #999;
    margin: 0;
}


.articles .article-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
    color: #3db49c71;
    font-size: 26px;
}

.articles .article-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 0px;
    color: #3db49c71;
    font-size: 13px;
}

.articles .article-item .btn-read-more {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    text-decoration: none;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #3db49d;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    width: 100%;
    border: 2px solid #3db49d;
}

.articles .article-item .btn-read-more:hover {
    background: #3db49d;
    color: #fff;
}

.articles .article-item .info {
    font-size: 9px;
    color: #111;
    margin: 0 auto 15px auto;
    text-decoration: none;
    padding-top: 15px;
}

.articles .article-item a {
    font-size: 12px;
    color: #111;
    margin: 0 auto 15px auto;
    text-decoration: none;
    padding-top: 15px;
}

.articles .article-item a:hover {
    color: #3db49d
}

.articles .swiper-pagination {
    margin-top: 10px;
    position: relative;
}

.articles .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #3db49d;
}

.articles .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #3db49d;
}

@media (max-width: 767px) {
    .articles .article-item {
        height: 300px;
    }

    .articles .article-item .article-img {
        height: 160px;
    }
}

@media (max-width: 540px) {
    .articles .article-item {
        height: 300px;
    }

    .articles .article-item .article-img {
        height: 150px;
    }
}
</style>