<template>
    <div class="container">
        <div v-if="loading" class="d-flex justify-content-center align-items-center p-5">
            <div class="spinner-border text-dark m-5 p-3" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="" v-for="product in products" :key="product.id" v-else>
            <ul class="nav nav-tabs row d-flex">
                <li class="nav-item col-3" data-aos="zoom-in">
                    <a class="nav-link active show" data-bs-toggle="tab" href="#tab-1">
                        <i class="ri-gps-line d-none"></i>
                        <h4 class="d-none">FBSInnova</h4>
                        <img :src="product[2].attributes.logoTitle" class="product-logo img-fluid" />
                    </a>
                </li>
                <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="100">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab-2">
                        <i class="ri-store-line d-none"></i>
                        <h4 class="d-none">AkokoMarket</h4>
                        <img :src="product[1].attributes.logoTitle" class="product-logo img-fluid" />
                    </a>
                </li>
                <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="200">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab-3">
                        <i class="ri-sun-line d-none"></i>
                        <h4 class="d-none">Akokotakra</h4>
                        <img :src="product[0].attributes.logoTitle" class="product-logo img-fluid" />
                    </a>
                </li>
                <li class="nav-item col-3" data-aos="zoom-in" data-aos-delay="300">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab-4">
                        <i class="ri-body-scan-line d-none"></i>
                        <h4 class="d-none">InnovaSync</h4>
                        <img :src="product[3].attributes.logoTitle" class="product-logo img-fluid" />
                    </a>
                </li>
            </ul>

            <div class="tab-content" data-aos="fade-up">
                <div class="tab-pane active show" id="tab-1">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 text-start">
                            <h3>{{product[2].attributes.title}}</h3>
                            <p class="fst-italic">
                                <span>{{product[2].attributes.subtitle}}</span>
                            </p>
                            <p>
                                {{product[2].attributes.description}}
                            </p>
                            <div class="mt-4">
                                <h5>App Features</h5>
                            </div>
                            <ul v-for="feature in product[2].attributes.features" :key="feature">
                                <li><i class="ri-check-double-line"></i>{{feature}}</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <a :href="product[2].attributes.link" target="blank" class="btn-visit text-center">Explore</a>
                                <a href="https://play.google.com/store/apps/details?id=com.agroinnova.farmerbusinessschool2" target="blank">
                                    <img class="btn-download" src="../../assets/img/playstore.png" alt="download"/>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2 text-center">
                            <img :src="product[2].attributes.imageTitle" class="img-fluid rounded" />
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="tab-2">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 text-start">
                            <h3>{{product[1].attributes.title}}</h3>
                            <p class="fst-italic">
                                <span>{{product[1].attributes.subtitle}}</span>
                            </p>
                            <p>
                                {{product[1].attributes.description}}
                            </p>
                            <div class="mt-4">
                                <h5>What You Can Request</h5>
                            </div>
                            <ul v-for="feature in product[1].attributes.features" :key="feature">
                                <li><i class="ri-check-double-line"></i>{{feature}}</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <a :href="product[1].attributes.link" target="blank" class="btn-visit text-center">Explore</a>
                                <!-- <a href="https://play.google.com/store/apps/details?id=com.agroinnovagh.mobile_akokomarket" target="blank">
                                    <img class="btn-download" src="../../assets/img/playstore.png" alt="download" />
                                </a> -->
                            </div>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2 text-center">
                            <img :src="product[1].attributes.imageTitle" class="img-fluid rounded" />
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="tab-3">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 text-start">
                            <h3>{{product[0].attributes.title}}</h3>
                            <p class="fst-italic">
                                <span>{{product[0].attributes.subtitle}}</span>
                            </p>
                            <p>
                                {{product[0].attributes.description}}
                            </p>
                            <div class="mt-4">
                                <h5>App Features</h5>
                            </div>
                            <ul v-for="feature in product[0].attributes.features" :key="feature">
                                <li><i class="ri-check-double-line"></i>{{feature}}</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <a :href="product[0].attributes.link" target="blank" class="btn-visit text-center">Explore</a>
                                <a href="https://play.google.com/store/apps/details?id=com.agroinnovagh.mobile_akokotakra" target="blank">
                                    <img class="btn-download" src="../../assets/img/playstore.png" alt="download" />
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2 text-center">
                            <img :src="product[0].attributes.imageTitle" class="img-fluid rounded" />
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="tab-4">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 text-start">
                            <h3>{{product[3].attributes.title}}</h3>
                            <p class="fst-italic">
                                <span>{{product[3].attributes.subtitle}}</span>
                            </p>
                            <p>
                                {{product[3].attributes.description}}
                            </p>
                            <div class="mt-4">
                                <h5>App Features</h5>
                            </div>
                            <ul v-for="feature in product[3].attributes.features" :key="feature">
                                <li><i class="ri-check-double-line"></i>{{feature}}</li>
                            </ul>
                            <div class="d-flex align-items-center">
                                <a :href="product[3].attributes.link" target="blank" class="btn-visit text-center">Explore</a>
                            </div>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2 text-center">
                            <img :src="product[3].attributes.imageTitle" class="img-fluid rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { productEndpoint } from "@/endpoint/endpoint"

export default {
    name: "Products",

    data() {
        return {
            products: [],
            loading: true
        }
    },

    mounted(){
        this.getProducts();
    },

    methods: {
        async getProducts(){
            try{
                const response = await axios.get(productEndpoint)
                const results = response?.data?.data
                this.products.push(results)
                if (response) {
                    this.loading = false
                }
            }catch(error){
                console.error(error)
                this.loading = true
            }
        }
    }
}
</script>

<style scoped>
span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #3db49d;
}

.Products .nav-tabs {
    border: 0;
}

.Products .nav-link {
    height: 80%;
    width:  auto;
    border: 2px solid #d4d6df;
    padding: 15px;
    transition: 0.3s;
    color: #2a2c39;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Products .nav-link i {
    padding-right: 15px;
    font-size: 48px;
}

.Products .nav-link h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.Products .nav-link:hover {
    color: #3db49d;
    border: 2px solid #3db49d;
}

.Products .nav-link.active {
    background: #fff;
    color: #fff;
    border-color: #3db49d;
}

.btn-visit {

    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    text-decoration: none;
    display: inline-block;
    padding: 14px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #3db49d;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    height: 48px;
    width: auto;
    border: 2px solid #3db49d;
}

.btn-download {
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    margin-top: 6px;
    margin-left: 10px;
    height: 53px;
    width: auto;
}

.btn-visit:hover {
    background: #3db49d;
    color: #fff;
    text-decoration: none;
}

@media (max-width: 768px) {
    .Products .nav-link i {
        padding: 0;
        line-height: 1;
        font-size: 36px;
    }
}

@media (max-width: 575px) {
    .Products .nav-link {
        padding: 15px;
    }

    .Products .nav-link i {
        font-size: 24px;
    }

}

@media (max-width: 375px) {
    .product-logo {
        max-width: 180%;
        height: auto;
    }
}

.Products .tab-content {
    margin-top: 30px;
}

.Products .tab-pane h3 {
    font-weight: 600;
    font-size: 26px;
}

.Products .tab-pane ul {
    list-style: none;
    padding: 0;
}

.Products .tab-pane ul li {
    padding-bottom: 0px;
}

.Products .tab-pane ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #3db49d;
}

.Products .tab-pane p:last-child {
    margin-bottom: 0;
}
</style>