<template>
    <section id="team" class="team">
        <div class="container text-start">

            <div class="section-title" data-aos="zoom-out">
                <h2>Our Company</h2>
                <p>Who We Are</p>
            </div>

            <div class="row">
                <div class="pb-3">
                    <p class="pb-2" data-aos="fade-up">
                        An Agri-Tech Company empowering smallholder farmers and improving agriculture in Africa by developing farmer-centered
                        technology to address challenges faced by farmers such as lack of access to ready market, climate-smart farming, lack of
                        access to inputs, farm traceability software, farm management software and farmer data collection tools.
                    </p>
                    <div data-aos="fade-up">
                        <h4 class="fw-bold">Our Vision</h4>
                        <p>Digitalize Agriculture in Africa.</p>
                    </div>
                    <div data-aos="fade-up">
                        <h4 class="fw-bold">Our Mission</h4>
                        <p>
                           Empower smallholder farmers , women and youth through Agriculture digitalization in Africa
                        </p>
                    </div>
                    <div class="pt-4 pb-5 text-center" data-aos="fade-up" data-aos-delay="100">
                        <h3 class="fw-bold">WE ARE PASSIONATE ABOUT</h3>
                        <div class="row">
                            <div class="believe pt-3 d-flex align-items-center justify-content-center flex-wrap">
                                <div class="col-lg-4 mt-4 mx-md-3 mt-md-4 mx-lg-3">
                                    <div class=" icon-box" data-aos="zoom-in-left" style="background-color: #73c2fb;">
                                        <div class="icon"><i class="bi bi-cloud" style="color: #fff;"></i></div>
                                        <h4 class="title">Innovation</h4>
                                        <p class="description">
                                            At Agro Innova Ltd we believe in digital agriculture innovation as a catalyst for empowering farmers and improving
                                            global food security
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 mt-5 mt-md-0 mx-md-3 mt-md-4 mx-lg-3">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="100"
                                        style="background-color: #6bc760;">
                                        <div class="icon"><i class="bi bi-star" style="color: #fff;"></i></div>
                                        <h4 class="title">Impact</h4>
                                        <p class="description">We are passionate in using our services to impact individual, homes, communities, countries and the African continent
                                        at large.</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 mt-5 mx-md-3 mt-md-4 mx-lg-3 ">
                                    <div class=" icon-box" data-aos="zoom-in-left" data-aos-delay="200"
                                        style="background-color: #3db49d;">
                                        <div class="icon"><i class="bi bi-heart" style="color: #fff;"></i>
                                        </div>
                                        <h4 class="title">Sustainability</h4>
                                        <p class="description">
                                            Most important to us is adopting a sustainability approach in all our products and services to create a better future
                                            for the company and customers.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-lg-4 mt-5 mx-md-3 mt-md-4 mx-lg-3">
                                    <div class="icon-box" data-aos="zoom-in-left" data-aos-delay="300"
                                        style="background-color: #e9bf06;">
                                        <div class="icon"><i class="bi bi-arrow-up-right" style="color:#fff;"></i></div>
                                        <h4 class="title">Scalability</h4>
                                        <p class="description">
                                            Agro Innova is passionate in scaling its products and services across communities and countries to bring about the
                                            desired change in Africa.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="pb-3" data-aos="zoom-out">
                    <h1 class="fw-bold">Meet Our Team</h1>
                </div>
            </div>

            <div v-if="loading" class="member-container d-flex justify-content-center align-items-center m-5 p-5">
                <div class="spinner-border text-dark m-5 p-3" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div v-else class="member-container row pb-5" v-for="person in team" :key="person.id">
                <div class="member-card col-lg-3 col-md-6 d-flex align-items-stretch" v-for="el in person" :key="el.attributes.name">
                    <div class="member" data-aos="fade-up">
                        <div class="member-img">
                            <img :src="el.attributes.imageTitle" class="img-fluid" :alt="el.attributes.name">
                            <div class="social">
                                <a target="blank" :href="el.attributes.twitter"><i class="bi bi-twitter"></i></a>
                                <a target="blank" :href="el.attributes.facebook"><i class="bi bi-facebook"></i></a>
                                <a target="blank" :href="el.attributes.instagram"><i class="bi bi-instagram"></i></a>
                                <a target="blank" :href="el.attributes.linkedin"><i class="bi bi-linkedin"></i></a>
                            </div>
                        </div>
                        <div class="member-info">
                            <a>
                                <h4>{{el.attributes.name}}</h4>
                            </a>
                            <span>{{el.attributes.role}}</span>
                        </div>
                    </div>
                </div>
            </div>


            <div class="member-container row" v-for="advisor in advisors" :key="advisor.id" v-show="false">
                <div class="pt-5">
                    <div class="pb-3" data-aos="zoom-out">
                        <h1 class="fw-bold">Our Advisors</h1>
                    </div>
                </div>
                <div class="member-card col-lg-3 col-md-6 d-flex align-items-stretch" v-for="el in advisor" :key="el.attributes.name">
                    <div class="member" data-aos="fade-up">
                        <div class="member-img">
                            <img :src="el.attributes.imageTitle" class="img-fluid" alt="">
                            <div class="social">
                                <a target="blank" :href="el.attributes.twitter"><i class="bi bi-twitter"></i></a>
                                <a target="blank" :href="el.attributes.facebook"><i class="bi bi-facebook"></i></a>
                                <a target="blank" :href="el.attributes.instagram"><i class="bi bi-instagram"></i></a>
                                <a target="blank" :href="el.attributes.linkedin"><i class="bi bi-linkedin"></i></a>
                            </div>
                        </div>
                        <div class="member-info">
                            <a>
                                <h4>{{el.attributes.name}}</h4>
                            </a>
                            <span>{{el.attributes.role}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div data-aos="fade-up">
                    <img src="../../../assets/img/web-banner.jpg" class="img-fluid" alt="Web Banner">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { teamEndpoint, advisorEndpoint } from '@/endpoint/endpoint';

export default {
    name: "Company",

    mounted(){
        document.title = "Our Company - Agro Innova Co Ltd is an Agri-Tech Company improving agriculture in Africa"
        this.getTeam();
        this.getAdvisor();
    },

    data() {
        return {
            team: [],
            advisors: [],
            loading: true
        }
    },

    methods: {
        async getTeam() {
            try {
                const response = await axios.get(teamEndpoint)
                const results = response?.data?.data
                this.team.push(results)
                if (response) {
                    this.loading = false
                }
            } catch (error) {
                this.loading = true
                console.error(error)
            }
        },

        async getAdvisor() {
            try {
                const response = await axios.get(advisorEndpoint)
                const results = response?.data?.data
                this.advisors.push(results)
                if (response) {
                    this.loading = false
                }
            } catch (error) {
                this.loading = true
                console.error(error)
            }
        }
    }
}
</script>

<style scoped>


section {
    padding: 120px 0 50px 0;
    overflow: hidden;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #3db49d;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2a2c39;
}

.believe .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    color: #fff;
    transition: all 0.4s ease-in-out;
    width: 100%;
}

.believe .icon-box:hover {
    transform: translateY(-10px);
}

.believe .icon {
    position: absolute;
    left: -20px;
    top: calc(50% - 30px);
}

.believe .icon i {
    font-size: 64px;
    line-height: 1;
    transition: 0.5s;
}

.believe .title {
    margin-left: 40px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}


.believe .description {
    font-size: 14px;
    margin-left: 40px;
    line-height: 24px;
    margin-bottom: 0;
}

.team {
    background: #fff;
}

.team .member-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.team .member-card {
    width: 25rem;
}

.team .member {
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
    position: relative;
    overflow: hidden;
}

.team .member .social {
    position: absolute;
    left: 0;
    bottom: 30px;
    right: 0;
    opacity: 0;
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.team .member .social a {
    transition: color 0.3s;
    margin: 0 3px;
    border-radius: 50px;
    width: 36px;
    height: 36px;
    background: #3db49d;
    transition: ease-in-out 0.3s;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.team .member .social a:hover {
    background: #6addc6;
}

.team .member .social i {
    font-size: 18px;
    line-height: 0;
}

.team .member .member-info {
    padding: 25px 15px;
}

.team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #2a2c39;
}

.team .member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
}

.team .member .member-info a{
    text-decoration: none;
}

.team .member .member-info h4:hover {
    color: #3db49d;
}

.team .member:hover .social {
    opacity: 1;
    bottom: 15px;
}
</style>