<template>
    <section id="partners" class="partners">
        <div class="container text-start">

            <div class="section-title" data-aos="zoom-out">
                <h2>Our Partners</h2>
                <p>Who we work with</p>
            </div>

            <div class="pb-3" data-aos="fade-up">
                <p>
                    We are confident that digitalizing agriculture in Africa is the way forward to address the
                    challenging needs of smallholder farmers within the continent. The alliance we have
                    share this vision with Agro Innova. We collaborate with these institutions, development partners
                    and government agencies to create specific solutions and services that are intended to meet the
                    growing needs of smallholder farmers.
                </p>
                <p>At Agro Innova our work is guided by our partnerships. We strongly believe that working
                    together with our partners around the world, will help Agro Innova achieve it's objectives
                    for empowering the smallholder farmers, jobs creation, education and sustainable food security.
                </p>
            </div>

            <div class="">
                <div class="pb-4 pt-3" data-aos="fade-up">
                    <h4 class="fw-bold">Partners</h4>
                </div>
            </div>

            <div v-if="loading" class="d-flex justify-content-center align-items-center p-5">
                <div class="spinner-border text-dark m-5 p-3" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div class="row mb-5 d-flex justify-content-center align-items-center" v-for="partner in partners" :key="partner.id" v-else>
                <div class="partner-card col-lg-3 col-md-6 d-flex align-items-stretch" v-for="el in partner" :key="el.attributes.title">
                    <div class="partner d-flex align-items-center" data-aos="fade-up">
                        <a :href="el.attributes.link" target="blank" class="stretched-link">
                            <div class="partner-img">
                                <img :src="el.attributes.imageTitle" class="img-fluid" :alt="el.attributes.title" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div data-aos="fade-up">
                    <img src="../../../assets/img/synergy-banner.jpg" class="img-fluid" alt="Synergy Banner">
                </div>
            </div>

            <div class="d-flex align-items-center justify-content-center pt-4" data-aos="fade-up" data-aos-delay="100">
                <div class="join text-center">
                    <p>
                        The agricultural value chain is big. We are always looking for a competent strategic
                        partner to work with to reach out more potential key actors or stakeholders along the value
                        chain.
                    </p>
                    <a href="/#Contact" class="become-partner">BECOME A STRATEGIC PARTNER</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { partnerEndpoint } from '@/endpoint/endpoint';

export default {
    name: "Partners",
    mounted() {
        document.title = "Our Partners - At Agro Innova our work is guided by our partnerships"
        this.getPartners();
    },
    data(){
        return {
            partners: [],
            loading: true
        }
    },
    methods: {
        async getPartners() {
            try {
                const response = await axios.get(partnerEndpoint)
                const results = response?.data?.data
                this.partners.push(results)
                if (response) {
                    this.loading = false
                }
            } catch (error) {
                this.loading = true
                console.error(error)
            }
        }
    }
}
</script>

<style scoped>
section {
    padding: 120px 0 50px 0;
    overflow: hidden;
}

.section-title {
    padding-bottom: 40px;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #3db49d;
    margin: 4px 10px;
}

.section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2a2c39;
}

.partners {
    background: #fff;
}

.partners .partner-card {
    width: 20rem;
}

.partners .partner {
    margin-bottom: 20px;
    overflow: hidden;
    height: 250px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.partners .partner .partner-img {
    height: inherit;
    position: relative;
    overflow: hidden;
}

.partners .partner a {
    text-decoration: none;
}

.partners .partner .partner-info {
    padding: 25px 15px;
}

.partners .partner .partner-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #2a2c39;
}

.partners .partner .partner-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
}

.partners .partner .partner-info p {
    font-style: italic;
    font-size: 14px;
    line-height: 26px;
    color: #777777;
}

.partners .join {
    width: 50%;
}
.partners .become-partner {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    text-decoration: none;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #3db49d;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #3db49d;
}

.partners .become-partner:hover {
    background: #3db49d;
    color: #fff;
}

@media (max-width: 767px) {
    
.partners .join {
    width: 100%
}

}
</style>