<template>
    <footer id="footer" class="text-center py-4">
        <div class="container">
            <div class="pt-3">
                <h3>Agro Innova Co. Ltd</h3>
            </div>
            <div class="p-3">
                <strong>Offices:</strong>
                <div class="offices-links">
                    <a href="https://www.google.com/maps/place/AkokoMarket+Ghana/@5.6695421,-0.1557767,15z/data=!3m1!4b1!4m5!3m4!1s0xfdf9dea827dc623:0xbd093fcdfba398a4!8m2!3d5.6694641!4d-0.1470546" 
                        target="blank"
                        rel="noopener noreferrer">
                        <p>
                            <span><i class="bi bi-caret-right-fill"></i></span>
                            Ghana: No. 31 Ogbojo Koo Street, Ogbojo - East Legon, Accra
                        </p>
                    </a>
                </div>
            </div>
            <div class="social-links">
                <a href="https://twitter.com/agroinnovaltd" target="blank" class="twitter"><i class="bx bxl-twitter"></i></a>
                <a href="https://web.facebook.com/AgroInnovaLLC" target="blank" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a href="https://www.instagram.com/agroinnovagh/" target="blank" class="instagram"><i class="bx bxl-instagram"></i></a>
                <a href="https://www.linkedin.com/company/agroinnovagh" target="blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                <a href="https://www.youtube.com/@agroinnovagh" target="blank" class="youtube"><i class="bx bxl-youtube"></i></a>
                <a href="mailto:info@agroinnovagh.com" target="blank" class="envelope"><i class="bi bi-envelope"></i></a>
                <a href="https://wa.me/233240956276?text=Hello%20Agro%20Innova" target="blank" class="whatsapp"><i class="bx bxl-whatsapp"></i></a>
            </div>
        </div>
        <div>
            <div class="copyright">
                <strong><span>Agro Innova Company Limited </span></strong>&copy; 2023 | All Rights Reserved
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    components: {

    }
}
</script>

<style scoped>

    #footer {
        background: #3db49d;
        color: #fff;
        font-size: 14px;
    }

    #footer h3 {
        font-size: 36px;
        font-weight: 700;
        padding: 0;
        margin: 0 0 15px 0;
    }

    #footer p {
        font-size: 15;
        font-style: italic;
        padding: 0;
        margin: 0 0 30px 0;
    }

    #footer .social-links {
        margin: 0 0 40px 0;
    }

    #footer .social-links a {
        font-size: 18px;
        display: inline-block;
        background: #ffffff;
        line-height: 1;
        padding: 8px 0;
        margin-right: 4px;
        border-radius: 50%;
        text-align: center;
        width: 36px;
        height: 36px;
        transition: 0.3s;
    }

    #footer .offices-links a {
       color: #fff !important;
       text-decoration: none;
    }

    #footer .social-links a:hover {
        background: #6addc6;
        color: #fff;
        text-decoration: none;
    }

    #footer .copyright {
        margin: 0 0 5px 0;
    }

    #footer .credits {
        font-size: 13px;
    }
</style>